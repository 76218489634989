export default [
    {
        id : 0,
        title: "OpenSea",
        link:"https://opensea.io/",
        image: "./assets/images/nftIcons/opensea.png",
    },
    {
        id : 1,
        title: "Nifty Gateway",
        link:"https://www.niftygateway.com/",
        image: "./assets/images/nftIcons/niftygateway.png",
    },
    {
        id : 2,
        title: "SuperRare",
        link:"https://superrare.com/",
        image: "./assets/images/nftIcons/superrare.png",
    },
    {
        id : 3,
        title: "Foundation",
        link:"https://foundation.app/",
        image: "./assets/images/nftIcons/foundation.png",
    },
]