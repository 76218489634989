<template>
  <!-- Realtime prices (Binance, exchange rate) -->
  <div class="realtimePrices">
    <p>BTC/USDT: {{ btcPrice }}</p>
    <p>ETH/USDT: {{ ethPrice }}</p>
    <p>USD/KRW: {{ usdKRWRate }}</p>
    <p>USD/EUR: {{ usdEURRate }}</p>
    <p>USD/JPY: {{ usdJPYRate }}</p>
  </div>

  <!-- Navigation Bar -->~
  <div id="navBar">
    <img class="mainLogo" src="./assets/images/mainLogo1.png" alt="" />
    <!-- Google Login and Logout -->
    <img
      v-if="modalOpen == false"
      class="googleLogin"
      @click="modalOpen = true"
      src="./assets/images/navbarIcons/login.svg"
    />
    <img
      v-else
      class="googleLogin"
      @click="modalOpen = false"
      src="./assets/images/navbarIcons/logout.svg"
    />
  </div>

  <!-- <div
    id="adsgoeshere"
    style="background: #1d1f29; padding-top: 60px; text-align: center"
    v-html="adsenseContent"
  /> -->

  <!-- Main content page 1 -->
  <div id="contentPage">
    <!-- Searchbar -->
    <div class="newsContent">
      <a :href="articleUrl" target="_blank">{{ limitedTitle }}</a>
    </div>
    <div class="searchBar__wrap">
      <img src="./assets/images/navbarIcons/searchBar.svg" />
      <input
        class="searchBar"
        type="text"
        v-model="searchQuery"
        placeholder="Search a link . . ."
      />
    </div>
    <div class="categoryBox" v-for="(cryptoData, i) in cryptoData" :key="i">
      <h2>{{ cryptoData.categoryName }}</h2>
      <div class="linkBox--wrap">
        <div
          class="linkBox--content"
          v-for="(categoryLink, i) in filteredSearch(cryptoData.categoryLink)"
          :key="i"
        >
          <a :href="categoryLink.link" target="_blank">
            <img :src="require(`${categoryLink.image}`)" alt="" />
            <h3>{{ categoryLink.title }}</h3>
          </a>
          <button v-if="user" @click="saveLink(categoryLink)">+</button>
        </div>
        <p v-if="filteredSearch(cryptoData.categoryLink).length === 0">
          NO LINKS FOUND
        </p>
      </div>
    </div>
    <div id="favorites" class="categoryBox">
      <h2>FAVORITES</h2>
      <div class="linkBox--wrap">
        <div class="linkBox--content" v-for="(a, i) in link" :key="i">
          <a :href="link[i].link" target="_blank">
            <img :src="require(`${link[i].image}`)" alt="" />
            <h3>{{ link[i].title }}</h3>
          </a>
          <button v-if="user" @click="deleteLink(link[i])">-</button>
        </div>
        <p v-if="user"></p>
        <p v-else>Login to add your favorite links here!</p>
      </div>
    </div>
    <div class="newContent1">New_Content_1</div>
    <div class="newContent2">New_Content_2</div>
  </div>
  <div v-if="modalOpen == true" class="loginModal">
    <div class="loginModal__main">
      <h3 v-if="user" @click="signOut()">Logout</h3>
      <h3 v-else @click="signInWithGoogle">Login</h3>
      <h3>Mypage</h3>
    </div>
  </div>
</template>

<script>
import axios from "axios";

// Import local json data files.
import cryptoExchange from "./assets/jsonData/cryptoExchange";
import cryptoFoundation from "./assets/jsonData/cryptoFoundation";
import cryptoNews from "./assets/jsonData/cryptoNews";
import cryptoInfo from "./assets/jsonData/cryptoInfo";
import cryptoComm from "./assets/jsonData/cryptoComm";
import cryptoNFT from "./assets/jsonData/cryptoNFT";

// Import the functions you need from Firebase
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// Configure connection with Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBe_TN5qx3Dl0pjnnyBnmCQcZSQGdFaTT0",
  authDomain: "coinlink-3ce2b.firebaseapp.com",
  databaseURL: "https://coinlink-3ce2b-default-rtdb.firebaseio.com",
  projectId: "coinlink-3ce2b",
  storageBucket: "coinlink-3ce2b.appspot.com",
  messagingSenderId: "715284955877",
  appId: "1:715284955877:web:1144b31a5fb8fd72d3ca0c",
  measurementId: "G-HMGQ615R9F",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default {
  name: "App",

  // Data
  data() {
    return {
      cryptoData: [
        { categoryName: "EXCHANGE", categoryLink: cryptoExchange },
        { categoryName: "FOUNDATION", categoryLink: cryptoFoundation },
        { categoryName: "NEWS/ISSUES", categoryLink: cryptoNews },
        { categoryName: "ANALYTICS/INFO", categoryLink: cryptoInfo },
        { categoryName: "COMMUNITIES", categoryLink: cryptoComm },
        { categoryName: "NFT", categoryLink: cryptoNFT },
      ],
      searchQuery: "", // For searchbar
      // adsenseContent: '', // For Google Adsense
      btcPrice: 0,
      ethPrice: 0,
      usdKRWRate: 0,
      usdEURRate: 0,
      usdJPYRate: 0,
      user: null,
      modalOpen: false,
      link: [],
      articles: [],
      currentArticleIndex: 0,
    };
  },

  // Methods
  methods: {
    async signInWithGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();
      try {
        await firebase.auth().signInWithPopup(provider);
        // User is signed in.
        location.reload();
      } catch (error) {
        console.error(error);
        // An error occurred.
      }
    },

    onAuthStateChanged() {
      firebase.auth().onAuthStateChanged((user) => {
        this.user = user;
      });
    },

    async signOut() {
      try {
        await firebase.auth().signOut();
        // User is signed out.
        location.reload();
      } catch (error) {
        console.error(error);
        // An error occurred.
      }
    },

    async saveLink(link) {
      try {
        const user = firebase.auth().currentUser;
        if (!user) {
          return alert("Please log in to save links.");
        }
        const existingLink = await db
          .collection("users")
          .doc(user.uid)
          .collection("links")
          .doc(link.title)
          .get(); // Retrieve the document instead of listening for changes

        if (existingLink.exists) {
          alert("Link already saved.");
        } else {
          await db
            .collection("users")
            .doc(user.uid)
            .collection("links")
            .doc(link.title)
            .set(link);
        }
      } catch (error) {
        console.error(error);
        alert("Error saving link. Please try again later.");
      }
    },

    async deleteLink(link) {
      try {
        const user = firebase.auth().currentUser;
        if (!user) {
          return alert("Please log in to delete links.");
        }
        await db
          .collection("users")
          .doc(user.uid)
          .collection("links")
          .doc(link.title)
          .delete();
      } catch (error) {
        console.error(error);
        alert("Error deleting link. Please try again later.");
      }
    },
  },

  // Computed
  computed: {
    filteredSearch() {
      return (categoryLink) => {
        return categoryLink.filter((categoryLink) => {
          return categoryLink.title
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      };
    },

    articleTitle() {
      return this.articles.length > 0
        ? this.articles[this.currentArticleIndex].title
        : "";
    },
    articleUrl() {
      return this.articles.length > 0
        ? this.articles[this.currentArticleIndex].url
        : "";
    },
    limitedTitle() {
      const maxChars = 100; // Maximum number of characters for the title
      const title = this.articleTitle;
      if (title.length > maxChars) {
        return title.slice(0, maxChars) + "..."; // Add ellipsis to indicate the title is truncated
      } else {
        return title;
      }
    },
  },

  // Created
  created() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const linkCollection = await db
            .collection("users")
            .doc(user.uid)
            .collection("links")
            .get();

          // Listen for changes to the links collection
          db.collection("users")
            .doc(user.uid)
            .collection("links")
            .onSnapshot((querySnapshot) => {
              const links = querySnapshot.docs.map((doc) => doc.data());
              this.link = links;
            });

          const links = linkCollection.docs.map((doc) => doc.data());
          this.link = [...links, ...this.link];
        } catch (error) {
          console.error(error);
          alert("Error retrieving saved links. Please try again later.");
        }
      } else {
        this.link = [];
      }
    });

    const webSocket = new WebSocket(
      "wss://stream.binance.com:9443/ws/!ticker@arr"
    );
    webSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      data.forEach((ticker) => {
        if (ticker.s === "BTCUSDT") {
          this.btcPrice = parseFloat(ticker.c).toFixed(2);
        }
        if (ticker.s === "ETHUSDT") {
          this.ethPrice = parseFloat(ticker.c).toFixed(2);
        }
      });
    };

    axios
      .get("https://api.exchangerate-api.com/v4/latest/USD")
      .then((response) => {
        const data = response.data;
        this.usdKRWRate = data.rates.KRW.toFixed(2);
        this.usdEURRate = data.rates.EUR.toFixed(2);
        this.usdJPYRate = data.rates.JPY.toFixed(2);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        "https://newsapi.org/v2/everything?q=crypto&sortBy=publishedAt&language=en&apiKey=4fbc4f231c7e4dfd9007035436b0d610"
      )
      .then((response) => {
        // Handle the response data and display the news articles in your component
        this.articles = response.data.articles;
        setInterval(() => {
          this.currentArticleIndex =
            (this.currentArticleIndex + 1) % this.articles.length;
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // Mounted
  mounted() {
    // this.adsenseContent = document.getElementById('divadsensedisplaynone').innerHTML
    this.onAuthStateChanged();
  },

  // Before Unmounting
  beforeUnmount() {
    this.webSocket.close();
  },
};
</script>

<style>
html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  height: 100%;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: inherit;
}

body {
  background-image: url("./assets/images/bgDesktop.png");
  /* background: dodgerblue; */
  background-attachment: fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

#app {
  -moz-box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: "Avenir", sans-serif;
  justify-content: flex-start;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: center;
  width: 100%;
}

.realtimePrices {
  align-items: center;
  background: #00cccc;
  color: #000033;
  display: flex;
  top: 0;
  left: 0;
  height: 1.6rem;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.realtimePrices p {
  border-right: 2px solid white;
  font-size: 18px;
  font-style: italic;
  font-weight: bold;
  margin: 0 0.2rem;
  width: 100%;
}

.realtimePrices p:last-of-type {
  border-right: 1px solid transparent;
}

#navBar {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 1.6rem;
  z-index: 9999;
}

.mainLogo {
  margin: 0;
  width: 12rem;
  margin: 0 1rem;
}

.googleLogin {
  border-radius: 50%;
  width: 1.5rem;
  margin: 0 1rem;
}

.googleLogin p {
  color: white;
  font-weight: bold;
  margin: 0;
  padding: 0 1rem;
}

#contentPage {
  top: 5.6rem;
  position: fixed;
  align-content: center;
  align-items: stretch;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 6fr 6fr;
  justify-content: center;
  justify-items: center;
  width: 100%;
  height: calc(100vh - 5.6rem);
  padding: 0 0.5rem 0.5rem;
}

#favorites {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 7;
  height: 100%;
}

.newsContent {
  grid-column-start: 1;
  grid-column-end: 5;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000033;
  color: white;
  border: 3px solid #00cccc;
  position: relative;
}

.newsContent a {
  font-weight: bold;
  width: 90%;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.searchBar__wrap {
  display: flex;
  position: relative;
  flex-direction: row;
  grid-column-end: 5;
  grid-column-start: 7;
  width: 100%;
}

.searchBar__wrap img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: auto;
  padding: 0.5rem;
}

.searchBar {
  background: rgba(245, 245, 245, 0.75);
  border: 1px solid white;
  color: #000033;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  border-radius: 100rem;
}

.searchBar:hover,
.searchBar:focus {
  transition: 0.3s;
  background: #00cccc;
}

.searchBar:focus {
  border: 1px solid white;
  outline: none;
}

.categoryBox {
  background: rgba(245, 245, 245, 0.75);
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  overflow: auto;
  border-radius: 10px;
}

.categoryBox h2 {
  color: #000033;
  font-size: 18px;
  background: rgba(245, 245, 245, 0.75);
  padding: 0.5rem;
  margin: 0;
}

.linkBox--wrap {
  align-items: center;
  border-top: 1px solid whitesmoke;
  flex-direction: row;
  justify-content: space-between;
  overflow: auto;
}

.linkBox--content {
  align-items: center;
  position: relative;
  border-bottom: 1px solid white;
  color: #000033;
  display: flex;
  flex-direction: row;
  height: 1.5rem;
  justify-content: space-between;
}

.linkBox--content:hover {
  background: #00cccc;
  transition: 0.5s;
}

.linkBox--content a img {
  border-radius: 50%;
  height: 100%;
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.2rem;
}

.linkBox--content h3 {
  font-size: 14px;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 70%;
}

.linkBox--content a {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.linkBox--content button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  color: #000033;
  border: 1px solid #000033;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}

.linkBox--content button:hover {
  background: white;
  transition: 0.3s;
}

.newContent1 {
  grid-column-start: 1;
  grid-column-end: 4;
  background: goldenrod;
  width: 100%;
  border-radius: 10px;
}

.newContent2 {
  grid-column-start: 4;
  grid-column-end: 7;
  background: dodgerblue;
  width: 100%;
  border-radius: 10px;
}

.loginModal {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  top: 5.6rem;
  position: fixed;
  width: 100%;
  background: #36363680;
}

.loginModal__main {
  background: whitesmoke;
  padding: 1rem;
  text-align: center;
  width: 20rem;
  margin-bottom: 5.6rem;
}

.loginModal__main h3 {
  font-size: 18px;
  color: #000033;
}

.loginModal__main h3:hover {
  color: #00cccc;
}

@media only screen and (max-width: 1650px) {
  .newsContent a {
    font-size: 14px;
  }
  .linkBox--content h3 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1500px) {
  #contentPage {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 0.5fr 6fr 6fr 6fr;
  }

  #favorites {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 4;
    height: 100%;
  }

  .newsContent {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .searchBar__wrap {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  .newContent1 {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .newContent2 {
    grid-column-start: 3;
    grid-column-end: 5;
  }
}

@media only screen and (max-width: 1200px) {
  .realtimePrices p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 800px) {
  body {
    background-image: url("./assets/images/bgMobile.png");
  }
  #app {
    overflow: auto;
  }

  #navBar {
    height: 3rem;
    background: #000033;
    padding: 0 0.5rem 0 0.4rem;
  }

  .mainLogo {
    width: 9rem;
    margin: 0;
  }

  .googleLogin {
    margin: 0;
    width: 2rem;
  }

  #contentPage {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 1fr 6fr 6fr 6fr 6fr 6fr;
    padding: 0 2rem 2rem;
    height: 50rem;
    position: relative;
    top: 4.6rem;
  }

  .newsContent {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .newsContent a {
    font-size: 12px;
  }

  #favorites {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  #favorites h3 {
    margin: 0;
  }

  .searchBar__wrap {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .searchBar__wrap img {
    padding: 0.2rem;
  }

  .searchBar {
    font-size: 10px;
  }

  .newContent1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
  }

  .newContent2 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
  }

  .realtimePrices p {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }

  .categoryBox h2 {
    font-size: 14px;
  }

  .linkBox--content:hover {
    background: transparent;
    transition: 0s;
  }

  .linkBox--content a img {
    padding: 0.35rem;
  }

  .linkBox--content h3 {
    font-size: 10px;
    margin-left: 1rem;
  }

  .loginModal {
    top: 3.6rem;
  }

  .loginModal__main {
    width: 10rem;
  }
}
</style>
