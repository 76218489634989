export default [
    {
        id : 0,
        title: "CoinDesk",
        link: "https://www.coindesk.com/",
        image: "./assets/images/newsIcons/coindesk.png",
    },
    {
        id : 1,
        title: "Crypto News",
        link: "https://cryptonews.com/",
        image: "./assets/images/newsIcons/cryptonews.png",
    },
    {
        id : 2,
        title: "Decrypt",
        link: "https://decrypt.co/",
        image: "./assets/images/newsIcons/decrpyt.png",
    },
    {
        id : 3,
        title: "Bankless",
        link: "https://www.bankless.com/",
        image: "./assets/images/newsIcons/bankless.png",
    },
    {
        id : 4,
        title: "BeInCrypto",
        link: "https://beincrypto.com/",
        image: "./assets/images/newsIcons/beincrypto.png",
    },
    {
        id : 5,
        title: "The Block",
        link: "https://www.theblock.co/",
        image: "./assets/images/newsIcons/theblock.png",
    },
    {
        id : 6,
        title: "Bitcoin Magazine",
        link: "https://bitcoinmagazine.com/",
        image: "./assets/images/newsIcons/bitcoinmagazine.png",
    },
    {
        id : 7,
        title: "Blockworks",
        link: "https://blockworks.co/",
        image: "./assets/images/newsIcons/blockworks.png",
    },
    {
        id : 8,
        title: "Coin Bureau",
        link: "https://www.coinbureau.com/",
        image: "./assets/images/newsIcons/coinbureau.png",
    },
    {
        id : 9,
        title: "CoinGape",
        link: "https://coingape.com/",
        image: "./assets/images/newsIcons/coingape.png",
    },
    {
        id : 10,
        title: "The Defiant",
        link: "https://thedefiant.io/",
        image: "./assets/images/newsIcons/thedefiant.png",
    },
    {
        id : 11,
        title: "CoinTelegraph",
        link: "https://cointelegraph.com/",
        image: "./assets/images/newsIcons/cointelegraph.png",
    },
    {
        id : 12,
        title: "Crypto Daily",
        link: "https://cryptodaily.co.uk/",
        image: "./assets/images/newsIcons/cryptodaily.png",
    },
    {
        id : 13,
        title: "U.Today",
        link: "https://u.today/",
        image: "./assets/images/newsIcons/utoday.png",
    },
    {
        id : 14,
        title: "DailyCoin",
        link: "https://dailycoin.com/",
        image: "./assets/images/newsIcons/dailycoin.png",
    },
    {
        id : 15,
        title: "NewsBTC",
        link: "https://www.newsbtc.com/",
        image: "./assets/images/newsIcons/newsbtc.png",
    },
    {
        id : 16,
        title: "CryptoWeekly",
        link: "https://cryptoweekly.co/",
        image: "./assets/images/newsIcons/cryptoweekly.png",
    },
    {
        id : 17,
        title: "Bitcoinist",
        link: "https://bitcoinist.com/",
        image: "./assets/images/newsIcons/bitcoinist.png",
    },
    {
        id : 18,
        title: "Blockonomi",
        link: "https://blockonomi.com/",
        image: "./assets/images/newsIcons/blockonomi.png",
    },
    {
        id : 19,
        title: "TodayOnchain",
        link: "https://www.todayonchain.com/",
        image: "./assets/images/newsIcons/todayonchain.png",
    },
    {
        id : 20,
        title: "CryptoSlate",
        link: "https://cryptoslate.com/",
        image: "./assets/images/newsIcons/cryptoslate.png",
    },
    {
        id : 21,
        title: "CCN",
        link: "https://www.ccn.com/crypto/",
        image: "./assets/images/newsIcons/ccn.png",
    },
    {
        id : 22,
        title: "Forbes",
        link: "https://www.forbes.com/crypto-blockchain/",
        image: "./assets/images/newsIcons/forbescrypto.png",
    },
    {
        id : 23,
        title: "Blockchain Hub KR",
        link: "https://blockchainhub.kr/",
        image: "./assets/images/newsIcons/blockchainhub.png",
    },
]
