export default [
    {
        id : 0,
        title: "Binance",
        link: "https://www.binance.com/",
        image: "./assets/images/exchangeIcons/binance.png",
    },
    {
        id : 1,
        title: "Coinbase Exchange",
        link: "https://exchange.coinbase.com/",
        image: "./assets/images/exchangeIcons/coinbase.png",
    },
    {
        id : 2,
        title: "Kraken",
        link: "https://www.kraken.com/",
        image: "./assets/images/exchangeIcons/kraken.png",
    },
    {
        id : 3,
        title: "KuCoin",
        link: "https://www.kucoin.com/",
        image: "./assets/images/exchangeIcons/kucoin.png",
    },
    {
        id : 4,
        title: "Gate.io",
        link: "https://gate.io/",
        image: "./assets/images/exchangeIcons/gate.png",
    },
    {
        id : 5,
        title: "Bitfinex",
        link: "https://www.bitfinex.com/",
        image: "./assets/images/exchangeIcons/bitfinex.png",
    },
    {
        id : 6,
        title: "OKX",
        link: "https://www.okx.com/",
        image: "./assets/images/exchangeIcons/okx.png",
    },
    {
        id : 7,
        title: "Bitstamp",
        link: "https://www.bitstamp.net/",
        image: "./assets/images/exchangeIcons/bitstamp.png",
    },
    {
        id : 8,
        title: "ByBit",
        link: "http://www.bybit.com/b",
        image: "./assets/images/exchangeIcons/bybit.png",
    },
    {
        id : 9,
        title: "Binance.US",
        link: "https://www.binance.us/en",
        image: "./assets/images/exchangeIcons/binance.png",
    },
    {
        id : 10,
        title: "bitFlyer",
        link: "https://bitflyer.com/en-us/",
        image: "./assets/images/exchangeIcons/bitflyer.png",
    },
    {
        id : 11,
        title: "Bithumb",
        link: "https://www.bithumb.com/",
        image: "./assets/images/exchangeIcons/bithumb.png",
    },
    {
        id : 12,
        title: "MEXC",
        link: "https://www.mexc.com/",
        image: "./assets/images/exchangeIcons/mexc.png",
    },
    {
        id : 13,
        title: "Gemini",
        link: "https://gemini.com/gemin",
        image: "./assets/images/exchangeIcons/gemini.png",
    },
    {
        id : 14,
        title: "Huobi",
        link: "https://www.huobi.com/",
        image: "./assets/images/exchangeIcons/huobi.png",
    },
    {
        id : 15,
        title: "Crypto.com",
        link: "https://crypto.com/exchangecyr",
        image: "./assets/images/exchangeIcons/cryptoexchange.png",
    },
    {
        id : 16,
        title: "Coincheck",
        link: "https://coincheck.com/",
        image: "./assets/images/exchangeIcons/coincheck.png",
    },
    {
        id : 17,
        title: "LBank",
        link: "https://www.lbank.info/",
        image: "./assets/images/exchangeIcons/lbank.png",
    },
    {
        id : 18,
        title: "Bitget",
        link: "https://www.bitget.com/",
        image: "./assets/images/exchangeIcons/bitget.png",
    },
    {
        id : 19,
        title: "BKEX",
        link: "https://www.bkex.com/",
        image: "./assets/images/exchangeIcons/bkex.png",
    },
    {
        id : 20,
        title: "BitMart",
        link: "https://bitmart.com/",
        image: "./assets/images/exchangeIcons/bitmart.png",
    },
    {
        id : 21,
        title: "Upbit",
        link: "https://upbit.com/",
        image: "./assets/images/exchangeIcons/upbit.png",
    },
    {
        id : 22,
        title: "Binance TR",
        link: "https://www.trbinance.com/",
        image: "./assets/images/exchangeIcons/trbinance.png",
    },
    {
        id : 23,
        title: "Bitrue",
        link: "https://www.bitrue.com/",
        image: "./assets/images/exchangeIcons/bitrue.png",
    },
    {
        id : 24,
        title: "Korbit",
        link: "https://www.korbit.co.kr/",
        image: "./assets/images/exchangeIcons/korbit.png",
    },
    {
        id : 25,
        title: "Capital.com",
        link: "https://capital.com/",
        image: "./assets/images/exchangeIcons/capitalcom.png",
    },
    {
        id : 26,
        title: "eToro",
        link: "https://www.etoro.com/",
        image: "./assets/images/exchangeIcons/etoro.png",
    },
    {
        id : 27,
        title: "PrimeXBT",
        link: "https://primexbt.com/",
        image: "./assets/images/exchangeIcons/primexbt.png",
    },
    {
        id : 28,
        title: "Bitcoin VN",
        link: "https://bitcoinvn.io/",
        image: "./assets/images/exchangeIcons/bitcoinvn.png",
    },
    {
        id : 29,
        title: "Exness",
        link: "https://www.exness.asia/",
        image: "./assets/images/exchangeIcons/exness.png",
    },
    {
        id : 30,
        title: "Remitano",
        link: "https://remitano.com/",
        image: "./assets/images/exchangeIcons/remitano.png",
    },
    {
        id : 31,
        title: "Coinbase Pro",
        link: "https://pro.coinbase.com/",
        image: "./assets/images/exchangeIcons/coinbasepro.png",
    },
]