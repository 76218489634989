export default [
    {
        id : 0,
        title: "Bitcoin",
        link: "https://bitcoin.org/",
        image: "./assets/images/cryptoIcons/btc.png",
    },
    {
        id : 1,
        title: "Ethereum",
        link: "https://www.ethereum.org/",
        image: "./assets/images/cryptoIcons/eth.png",
    },
    {
        id : 2,
        title: "Tether",
        link: "https://tether.to/",
        image: "./assets/images/cryptoIcons/usdt.png",
    },
    {
        id : 3,
        title: "BNB",
        link: "https://bnbchain.org/en",
        image: "./assets/images/cryptoIcons/bnb.png",
    },
    {
        id : 4,
        title: "USD Coin",
        link: "https://www.centre.io/usdc",
        image: "./assets/images/cryptoIcons/usdc.png",
    },
    {
        id : 5,
        title: "XRP",
        link: "https://xrpl.org/",
        image: "./assets/images/cryptoIcons/xrp.png",
    },
    {
        id : 6,
        title: "Cardano",
        link: "https://www.cardano.org/",
        image: "./assets/images/cryptoIcons/ada.png",
    },
    {
        id : 7,
        title: "Polygon",
        link: "https://polygon.technology/",
        image: "./assets/images/cryptoIcons/matic.png",
    },
    {
        id : 8,
        title: "Dogecoin",
        link: "http://dogecoin.com/",
        image: "./assets/images/cryptoIcons/doge.png",
    },
    {
        id : 9,
        title: "Binance USD",
        link: "https://www.binance.com/en/busd",
        image: "./assets/images/cryptoIcons/busd.png",
    },
    {
        id : 10,
        title: "Solana",
        link: "https://solana.com/",
        image: "./assets/images/cryptoIcons/sol.png",
    },
    {
        id : 11,
        title: "Polkadot",
        link: "https://polkadot.network/",
        image: "./assets/images/cryptoIcons/dot.png",
    },
    {
        id : 12,
        title: "Shiba Inu",
        link: "https://shibatoken.com/",
        image: "./assets/images/cryptoIcons/shibatoken.svg",
    },
    {
        id : 13,
        title: "Dai",
        link: "http://www.makerdao.com/",
        image: "./assets/images/cryptoIcons/dai.png",
    },
    {
        id : 14,
        title: "TRON",
        link: "https://tron.network/",
        image: "./assets/images/cryptoIcons/trx.png",
    },
    {
        id : 15,
        title: "Litecoin",
        link: "https://litecoin.org/",
        image: "./assets/images/cryptoIcons/ltc.png",
    },
    {
        id : 16,
        title: "Avalanche",
        link: "https://avax.network/",
        image: "./assets/images/cryptoIcons/avax.png",
    },
    {
        id : 17,
        title: "Uniswap",
        link: "https://uniswap.org/",
        image: "./assets/images/cryptoIcons/uni.png",
    },
    {
        id : 18,
        title: "Cosmos",
        link: "https://cosmos.network/",
        image: "./assets/images/cryptoIcons/atom.png",
    },
    {
        id : 19,
        title: "Wrapped Bitcoin",
        link: "https://wbtc.network/",
        image: "./assets/images/cryptoIcons/wbtc.png",
    },
    {
        id : 20,
        title: "Chainlink",
        link: "https://chain.link/",
        image: "./assets/images/cryptoIcons/link.png",
    },
    {
        id : 21,
        title: "UNUS SED LEO",
        link: "https://www.bitfinex.com/",
        image: "./assets/images/cryptoIcons/leo.png",
    },
    {
        id : 22,
        title: "Toncoin",
        link: "https://ton.org/",
        image: "./assets/images/cryptoIcons/ton.png",
    },
    {
        id : 23,
        title: "Ethereum Classic",
        link: "https://ethereumclassic.org/",
        image: "./assets/images/cryptoIcons/etc.png",
    },
    {
        id : 24,
        title: "OKB",
        link: "https://www.okx.com/",
        image: "./assets/images/cryptoIcons/okb.png",
    },
]