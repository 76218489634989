export default [
    {
        id : 0,
        title: "CoinMarketCap",
        link: "https://coinmarketcap.com/",
        image: "./assets/images/infoIcons/coinmarketcap.png",
    },
    {
        id : 1,
        title: "BitInfoCharts",
        link: "https://bitinfocharts.com/top-100-richest-bitcoin-addresses.html",
        image: "./assets/images/infoIcons/bitinfocharts.png",
    },
    {
        id : 2,
        title: "Flipside",
        link: "https://flipsidecrypto.xyz/",
        image: "./assets/images/infoIcons/flipside.png",
    },
    {
        id : 3,
        title: "TradingView",
        link: "https://www.tradingview.com/",
        image: "./assets/images/infoIcons/tradingview.png",
    },
    {
        id : 4,
        title: "LookIntoBitcoin",
        link: "https://www.lookintobitcoin.com/charts/stock-to-flow-model/",
        image: "./assets/images/infoIcons/lookintobitcoin.png",
    },
    {
        id : 5,
        title: "Crypto Bubbles",
        link: "https://cryptobubbles.net/",
        image: "./assets/images/infoIcons/cryptobubbles.png",
    },
    {
        id : 6,
        title: "Coinglass",
        link: "https://www.coinglass.com/en",
        image: "./assets/images/infoIcons/coinglass.png",
    },
    {
        id : 7,
        title: "LunarCrush",
        link: "https://lunarcrush.com/",
        image: "./assets/images/infoIcons/lunarcrush.png",
    },
    {
        id : 8,
        title: "CoinGecko",
        link: "https://www.coingecko.com/",
        image: "./assets/images/infoIcons/coingecko.png",
    },
    {
        id : 9,
        title: "CryptoCompare",
        link: "https://www.cryptocompare.com/",
        image: "./assets/images/infoIcons/cryptocompare.png",
    },
    {
        id : 10,
        title: "Token Metrics",
        link: "https://www.tokenmetrics.com/",
        image: "./assets/images/infoIcons/tokenmetrics.png",
    },
    {
        id : 11,
        title: "CoinLobster",
        link: "https://coinlobster.com/",
        image: "./assets/images/infoIcons/coinlobster.png",
    },
    {
        id : 12,
        title: "WhatToMine",
        link: "https://whattomine.com/",
        image: "./assets/images/infoIcons/whattomine.png",
    },
    {
        id : 13,
        title: "CryptoMiso",
        link: "https://www.cryptomiso.com/",
        image: "./assets/images/infoIcons/cryptomiso.png",
    },
    {
        id : 14,
        title: "Coin360",
        link: "https://coin360.com/",
        image: "./assets/images/infoIcons/coin360.png",
    },
    {
        id: 15,
        title: "Glassnode",
        link: "https://glassnode.com/",
        image : "./assets/images/infoIcons/glassnode.png",
    },
    {
        id: 16,
        title: "Cryptoquant",
        link: "https://dataguide.cryptoquant.com/",
        image : "./assets/images/infoIcons/cryptoquant.png",
    },
    {
        id: 17,
        title: "IntoTheBlock",
        link: "https://app.intotheblock.com/",
        image : "./assets/images/infoIcons/intotheblock.png",
    },
    {
        id: 18,
        title: "Nansen",
        link: "https://www.nansen.ai/",
        image : "./assets/images/infoIcons/nansen.png",
    },
    {
        id: 19,
        title: "Dune",
        link: "https://dune.com/home",
        image : "./assets/images/infoIcons/dune.png",
    },
    {
        id: 20,
        title: "Etherscan",
        link: "https://etherscan.io/",
        image : "./assets/images/infoIcons/etherscan.png",
    },
    {
        id: 21,
        title: "Santiment",
        link: "https://santiment.net/",
        image : "./assets/images/infoIcons/santiment.png",
    },
    {
        id: 22,
        title: "Messari",
        link: "https://messari.io/",
        image : "./assets/images/infoIcons/messari.png",
    },
]