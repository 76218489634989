export default [
    {
        id : 0,
        title: "r/CryptoCurrency",
        link: "https://www.reddit.com/r/CryptoCurrency/",
        image: "./assets/images/commIcons/r_cryptocurrency.png",
    },
    {
        id : 1,
        title: "r/Bitcoin",
        link: "https://www.reddit.com/r/Bitcoin/",
        image: "./assets/images/commIcons/r_bitcoin.png",
    },
    {
        id : 2,
        title: "r/ethereum",
        link: "https://www.reddit.com/r/ethereum/",
        image: "./assets/images/commIcons/r_ethereum.png",
    },
]